export const STATUS = {
  TOTAL: [1, 2],
  PROCESS: [1],
  FINISHED: [2],
};

export const FORMINFO = {
  HOLDER: 1,
  DRUG: 2,
};

export const LANGUAGE = {
  CN: 1,
  EN: 2,
};

export const USER = {
  id: 0,
  name: null,
  roleId: 0,
  permissions: {
    holder: '',
    drugs: '',
  },
  isResponsiblePersonnel: false,
};

export const BASIC_INFO_CHECKED = {
  drug: false,
  holder: false,
};

export const TEMPLATE = {
  holder: null,
  drugs: null,
};

export const IMPORT_FIELDS = {
  territory: null,
  overseas: null,
};

export const IMPORT_TYPE = ['territory', 'overseas'];

export const ROLE = {
  ADMIN: 1,
  EDITOR: 2,
  MANAGER: 3,
};

export const STEPINFO = {
  WAIT: 0,
  FINISHED: 1,
  STATUS: {
    0: 'wait',
    1: 'finish',
  },
};

export const REPORT_YEARS_VISIABLE = ['/drugs', '/holder', '/files'];

export const DATAHUB_FIELDS = [
  {
    key: 'controls',
    title: 'Controls',
    width: 150,
    fixed: 'left',
    type: 'button',
    visible: ['affirm'],
  },
  {
    key: 'recordID',
    title: 'Record ID',
    width: 150,
    fixed: 'left',
    link: true,
    type: 'input',
    isSearch: true,
    visible: ['publish', 'affirm'],
  },
  {
    key: 'materialGridSequence',
    title: 'Material Grid Sequence',
    width: 200,
    fixed: 'left',
    type: 'input',
    visible: ['publish', 'affirm'],
  },
  {
    key: 'remarks',
    title: 'Remark',
    width: 250,
    type: 'tag',
    visible: ['affirm'],
  },
  {
    key: 'action',
    title: 'Action',
    width: 150,
    type: 'select',
    isSearch: true,
    visible: ['affirm'],
  },
  {
    key: 'title',
    title: 'Title',
    width: 150,
    type: 'input',
    isSearch: true,
    visible: ['publish', 'affirm'],
  },
  {
    key: 'description',
    title: 'Material Grid-Material Description',
    width: 260,
    type: 'input',
    isSearch: true,
    visible: ['publish', 'affirm'],
  },
  {
    key: 'internationalName',
    title: 'Material Grid-International Name',
    width: 260,
    type: 'input',
    isSearch: true,
    visible: ['publish', 'affirm'],
  },
  {
    key: 'recordStatus',
    title: 'Record Status',
    width: 150,
    type: 'input',
    isSearch: true,
    visible: ['publish', 'affirm'],
  },
  {
    key: 'dateOfImplementation',
    title: 'Date of Implementation',
    width: 200,
    type: 'date',
    isSearch: true,
    visible: ['publish', 'affirm'],
  },
  {
    key: 'comments',
    title: 'Regulatory Comments',
    width: 250,
    type: 'tag',
    visible: ['publish', 'affirm'],
  },
  {
    key: 'siteName',
    title: 'Site Name',
    width: 150,
    type: 'input',
    visible: ['publish', 'affirm'],
  },
  {
    key: 'closingDate',
    title: 'Current Target Closing Date',
    width: 220,
    type: 'date',
    visible: ['publish', 'affirm'],
  },
  {
    key: 'chinaFilling',
    title: 'China Filling',
    width: 150,
    type: 'input',
    visible: ['publish', 'affirm'],
  },
  {
    key: 'sourceOfChange',
    title: 'Source of Change',
    width: 150,
    type: 'input',
    visible: ['publish', 'affirm'],
  },
  {
    key: 'status',
    title: 'Status',
    width: 100,
    type: 'input',
    visible: ['publish', 'affirm'],
  },
];
