import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { message as Message, Button, Form, Select } from 'antd';
import jsFileDownload from 'js-file-download';
import { DatahubTable } from '../components';
import { AppContext } from '../common';
import styles from './../scss/modules/list.module.scss';
import {
  getDatahubList,
  publishDatahub,
  synchroDatahub,
  exportPendingList,
  importPendingList,
} from '../api';

const statusConfig = [
  { id: '', name: 'all' },
  { id: '1', name: 'unpublished' },
  { id: '2', name: 'published' },
];

const queryModelConfig = {
  pageCount: 10,
  pageIndex: 1,
  page: 1,
  status: '',
  recordID: '',
  title: '',
  description: '',
  internationalName: '',
  recordStatus: '',
  siteName: '',
  dateOfImplementation: [],
};

const PublishList = () => {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [queryModel, setQueryModel] = useState(queryModelConfig);
  const [formList, setFormList] = useState({
    entityTotalCount: 0,
    entities: [],
  });

  const fileDownload = ({ headers, data }) => {
    const fileName = decodeURIComponent(
      headers['content-disposition'].split(';')[1].replace('filename=', '')
    );
    jsFileDownload(data, fileName);
  };

  const handleDownload = async (selectedRowKeys) => {
    try {
      dispatch({ type: 'loading', payload: true });
      const { headers, data } = await exportPendingList({
        ids: selectedRowKeys.join(','),
        status: queryModel.status,
      });
      fileDownload({ headers, data });
    } catch (err) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleUpload = async (file) => {
    try {
      setLoading(true);
      const {
        model: { addNum, message, updateNum },
      } = await importPendingList(file);

      Message.success(
        `${message}, ${t('common.update')}: ${updateNum}, ${t(
          'common.add'
        )}: ${addNum}`
      );
      setQueryModel({ ...queryModelConfig });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleSyncData = async () => {
    try {
      setLoading(true);
      const {
        model: { addNum, message, updateNum, syncTime },
      } = await synchroDatahub();

      Message.success(
        `${message}, ${t('common.update')}: ${updateNum}, ${t(
          'common.add'
        )}: ${addNum}, ${t('common.syncTime')}: ${syncTime}`
      );
      setQueryModel({
        ...queryModel,
        pageIndex: 1,
      });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    form.resetFields();
    setQueryModel({ ...queryModelConfig });
  };

  const handleSearch = (values) => {
    setQueryModel({
      ...queryModel,
      ...values,
      pageIndex: 1,
    });
  };

  const handleTableChange = (pagination) => {
    setQueryModel({
      ...queryModel,
      pageIndex: pagination.current,
      pageCount: pagination.pageSize,
    });
  };

  const handlePublish = async (values) => {
    try {
      await publishDatahub({ ids: values.join(',') });
      Message.success(t('message.success'));
      setQueryModel({
        ...queryModel,
        pageIndex: 1,
      });
    } catch (err) {}
  };

  const handleGetList = async () => {
    const {
      pageIndex,
      pageCount,
      page,
      status,
      recordID,
      title,
      description,
      internationalName,
      recordStatus,
      siteName,
      dateOfImplementation,
    } = queryModel;
    setLoading(true);
    try {
      const { model } = await getDatahubList({
        pageIndex,
        pageCount,
        'QueryModel.Page': page,
        'QueryModel.Status': status,
        'QueryModel.RecordID': recordID,
        'QueryModel.Title': title,
        'QueryModel.Description': description,
        'QueryModel.InternationalName': internationalName,
        'QueryModel.RecordStatus': recordStatus,
        'QueryModel.SiteName': siteName,
        'QueryModel.DateOfImplementation': dateOfImplementation?.join(','),
      });

      setFormList({ ...model });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetList(); // api get list
  }, [queryModel]);

  useEffect(() => {
    dispatch({ type: 'current', payload: 'publish' });
  }, []);

  return (
    <>
      <Form
        form={form}
        name="listSearch"
        className={styles.search}
        onFinish={handleSearch}
        style={{ margin: '20px' }}
      >
        <div className={styles.item_group}>
          <Form.Item
            name="status"
            label={t('datahub.status')}
            className={styles.item}
          >
            <Select placeholder={t('form.select')}>
              {statusConfig.map((item) => (
                <Option key={item.id} value={item.id}>
                  {t(`datahub.${item.name}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className={styles.btn_group}>
          <Button type="primary" htmlType="submit">
            {t('button.search')}
          </Button>
          <Button
            style={{ marginLeft: '8px' }}
            onClick={handleReset}
            loading={loading}
          >
            {t('button.reset')}
          </Button>
        </div>
      </Form>
      <DatahubTable
        type="publish"
        loading={loading}
        dataSource={formList.entities}
        queryModel={queryModel}
        pagination={{
          showTotal: (total) => t('message.tableTotalCount', { total }),
          pageSize: queryModel.pageCount,
          current: queryModel.pageIndex,
          total: formList.entityTotalCount,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
        onControl={handlePublish}
        onSyncData={handleSyncData}
        onDownload={handleDownload}
        onUpload={handleUpload}
        onSearch={handleSearch}
      />
    </>
  );
};

export default PublishList;
