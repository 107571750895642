import request from './request';

/**
 * get list
 * @param {string} params - query params
 * @returns - list
 */
export const getDatahubList = async (params) => {
  const response = await request({
    url: '/api/postMarket/list',
    method: 'get',
    params,
    token: true,
  });
  return response.data?.content;
};

/**
 * publish
 * @param {string} params
 * @returns - model
 */
export const publishDatahub = async (params) => {
  const response = await request({
    url: '/api/postMarket/publish',
    method: 'post',
    params,
    token: true,
  });

  return response.data?.content;
};

/**
 * affirm
 * @param {string} data
 * @returns - model
 */
export const affirmDatahub = async (data) => {
  const response = await request({
    url: '/api/postMarket/affirm',
    method: 'post',
    data,
    token: true,
  });

  return response.data?.content;
};

/**
 * sync datahub
 * @returns - model
 */
export const synchroDatahub = async () => {
  const response = await request({
    url: '/api/postMarket/synchroData',
    method: 'get',
    token: true,
  });
  return response.data?.content;
};

/**
 * export pending list
 * @param {string} params
 * @returns - true or false
 */
export const exportPendingList = async (params) => {
  const response = await request({
    url: '/api/postMarket/exportPendingList',
    method: 'post',
    params,
    token: true,
    responseType: 'arraybuffer',
  });

  return response;
};

/**
 * export publish list
 * @param {string} params
 * @returns - true or false
 */
export const exportPublishList = async (params) => {
  const response = await request({
    url: '/api/postMarket/exportPublishList',
    method: 'post',
    params,
    token: true,
    responseType: 'arraybuffer',
  });

  return response;
};

/**
 * import pending list
 * @param {*} data
 * @returns - true or false
 */
export const importPendingList = async (file) => {
  const data = new FormData();
  data.append('file', file);

  const response = await request({
    url: '/api/postMarket/importPendingList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    token: true,
  });

  return response.data?.content;
};

/**
 * import publish list
 * @param {*} data
 * @returns - true or false
 */
export const importPublishList = async (file) => {
  const data = new FormData();
  data.append('file', file);

  const response = await request({
    url: '/api/postMarket/importPublishList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    token: true,
  });

  return response.data?.content;
};

/**
 * get pending detail
 * @param {string} params - query params
 * @returns - list
 */
export const getPendingDetail = async (params) => {
  const response = await request({
    url: '/api/postMarket/pendingDetail',
    method: 'get',
    params,
    token: true,
  });
  return response.data?.content;
};

/**
 * save pending detail
 * @param {object} data
 * @returns - model
 */
export const savePendingDetail = async (data) => {
  const response = await request({
    url: '/api/postMarket/pendingDetailSave',
    method: 'post',
    data,
    token: true,
  });

  return response.data?.content;
};

/**
 * get comment list
 * @param {string} params - query params
 * @returns - list
 */
export const getCommentAndRemarkList = async (params) => {
  const response = await request({
    url: '/api/postMarket/publishDetail',
    method: 'get',
    params,
    token: true,
  });
  return response.data?.content;
};

/**
 * edit comment
 * @param {object} data
 * @returns - model
 */
export const editComment = async (data) => {
  const response = await request({
    url: '/api/postMarket/EditComment',
    method: 'post',
    data,
    token: true,
  });

  return response.data?.content;
};

/**
 * add remark
 * @param {object} data
 * @returns - model
 */
export const addRemark = async (data) => {
  const response = await request({
    url: '/api/postMarket/AddRemark',
    method: 'post',
    data,
    token: true,
  });

  return response.data?.content;
};

/**
 * delete comment
 * @param {string} params
 * @returns - model
 */
export const deleteComment = async (params) => {
  const response = await request({
    url: '/api/postMarket/DeleteComment',
    method: 'post',
    params,
    token: true,
  });

  return response.data?.content;
};

/**
 * add comment
 * @param {object} data
 * @returns - model
 */
export const addComment = async (data) => {
  const response = await request({
    url: '/api/postMarket/AddComment',
    method: 'post',
    data,
    token: true,
  });

  return response.data?.content;
};
