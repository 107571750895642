import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  message as Message,
  Form,
  Button,
  Input,
  DatePicker,
  Tag,
  Modal,
} from 'antd';
import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CommentsList } from './../components';
import styles from './../scss/modules/form.module.scss';
import itemStyles from './../scss/modules/item.module.scss';
import {
  getPendingDetail,
  savePendingDetail,
  editComment,
  deleteComment,
  addComment,
  getCommentAndRemarkList,
} from '../api';
import { AppContext, DATAHUB_FIELDS } from './../common';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const commentDateFormat = 'YYYY-MM-DD HH:mm';

const PublishDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const { TextArea } = Input;
  const { Item } = Form;
  const [form] = Form.useForm();
  const [formComment] = Form.useForm();
  const { id } = useParams();
  const [formFields, setFormFields] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [commentsData, setCommentsData] = useState({});
  const [modalSaveVisible, setModalSaveVisible] = useState(false);
  const [modalCommentVisible, setModalCommentVisible] = useState(false);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const handleCommentData = (title, key) => {
    setCommentsData({
      title,
      value: form.getFieldValue([key]),
    });
  };

  const handleModalCommentVisible = (data) => {
    const { id: dataId, author, text: comment, time } = data;

    if (dataId) {
      formComment.setFieldsValue({
        id: dataId,
        author,
        comment,
        time: moment(time).format(commentDateFormat),
      });
    } else {
      formComment.resetFields();
    }
    setModalCommentVisible(true);
  };

  const handleEditComment = async (type) => {
    const data = await formComment.validateFields();
    const { id: dataId } = data;

    dispatch({ type: 'loading', payload: true });
    try {
      if (type === 'save' && dataId) {
        await editComment(data);
      } else if (type === 'save' && !dataId) {
        await addComment({ ...data, id });
      } else if (type === 'delete') await deleteComment({ id: dataId });

      Message.success(t('message.success'));
      setModalCommentVisible(false);

      const {
        model: { comments },
      } = await getCommentAndRemarkList({ id });
      form.setFieldsValue({ comments });

      handleCommentData('Regulatory Comments', 'comments');
    } catch (err) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handlePrevClick = () => {
    const hasHistory = location.key !== 'default';
    if (hasHistory) {
      navigate(-1);
    } else {
      navigate('/datahub/publish', { replace: true });
    }
  };

  const handleCancel = () => {
    if (isDisabled) return handlePrevClick();
    setModalCancelVisible(true);
  };

  const handleSave = async () => {
    const data = form.getFieldsValue();

    dispatch({ type: 'loading', payload: true });
    try {
      await savePendingDetail({ ...data, id });
      Message.success(t('message.success'));
      handlePrevClick();
    } catch (err) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  const handleFinish = async (values) => {
    if (isDisabled) return;
    setModalSaveVisible(true);
  };

  const handleValuesChange = (changedValues) => {
    const key = Object.keys(changedValues)[0];
    const value = changedValues[key];
    const formatDate =
      (key === 'dateOfImplementation' || key === 'closingDate') && value
        ? moment(value).format(dateFormat)
        : value;
    form.setFieldsValue({ [key]: formatDate });
  };

  const handleFormFields = () => {
    const fields = DATAHUB_FIELDS.filter((item) =>
      item.visible.includes('publish')
    ).map((field) => {
      const { key, title, type } = field;
      return {
        key,
        title,
        type,
      };
    });
    setFormFields(fields);
  };

  const getDetail = async () => {
    dispatch({ type: 'loading', payload: true });
    try {
      const { model } = await getPendingDetail({ id });
      const status =
        model.status === 1 ? t('datahub.unpublished') : t('datahub.published');

      form.setFieldsValue({ ...model, status });
      setIsDisabled(model.status !== 1);
    } catch (err) {
    } finally {
      dispatch({ type: 'loading', payload: false });
    }
  };

  useEffect(() => {
    handleFormFields();
    getDetail();
  }, [id]);

  useEffect(() => {
    dispatch({ type: 'current', payload: 'publish' });
  }, []);

  return (
    <>
      <div className={`${styles.wrapper} form-section`}>
        <div className={`${styles.content} ${styles.full}`}>
          <Form
            form={form}
            size="large"
            colon={false}
            layout="vertical"
            scrollToFirstError={true}
            onValuesChange={handleValuesChange}
            onFinish={handleFinish}
          >
            <div className={styles.group}>
              {formFields.map((field) => {
                const { key, title, type } = field;
                return (
                  <div className={itemStyles.item} key={key}>
                    {type === 'input' && (
                      <Item label={title} name={key}>
                        <Input
                          disabled={
                            isDisabled ||
                            [
                              'recordID',
                              'status',
                              'materialGridSequence',
                            ].includes(key)
                          }
                          placeholder={false ? '' : t('form.input')}
                          autoComplete="off"
                          allowClear
                        />
                      </Item>
                    )}
                    {type === 'date' && (
                      <Item
                        label={title}
                        name={key}
                        getValueProps={(value) => ({
                          value: value && moment(value, dateFormat),
                        })}
                      >
                        <DatePicker
                          disabled={isDisabled}
                          placeholder={false ? '' : t('form.select')}
                          format={dateFormat}
                          showTime
                          style={{ width: '100%' }}
                        />
                      </Item>
                    )}
                    {type === 'tag' && (
                      <Item label={title}>
                        {form.getFieldValue([key])?.[0]?.text && (
                          <Tag
                            className={`${itemStyles.tag} ${itemStyles.maxHeight}`}
                            onClick={() => handleCommentData(title, key)}
                          >
                            <CaretRightOutlined />
                            {form.getFieldValue([key])?.[0]?.text}
                          </Tag>
                        )}
                        {!isDisabled && (
                          <Tag
                            onClick={() => handleModalCommentVisible({})}
                            className={itemStyles.tag}
                            style={{
                              marginTop: 8,
                              background: 'white',
                              borderStyle: 'dashed',
                            }}
                          >
                            <PlusOutlined /> {t('button.addComment')}
                          </Tag>
                        )}
                      </Item>
                    )}
                  </div>
                );
              })}
            </div>
            <div className={styles.footer}>
              <Button size="large" type="default" onClick={handleCancel}>
                {t('button.cancel')}
              </Button>
              {!isDisabled && (
                <Button size="large" type="primary" htmlType="submit">
                  {t('button.submit')}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
      <Modal
        title={t('common.tips')}
        visible={modalSaveVisible}
        confirmLoading={state.loading}
        onCancel={() => setModalSaveVisible(false)}
        onOk={handleSave}
      >
        {t('message.pleaseConfirmSaveData')}
      </Modal>
      <Modal
        title={t('common.tips')}
        visible={modalCancelVisible}
        confirmLoading={state.loading}
        onCancel={() => setModalCancelVisible(false)}
        onOk={handlePrevClick}
      >
        {t('message.pleaseConfirmCancelData')}
      </Modal>
      <Modal
        title="Regulatory Comments"
        visible={modalCommentVisible}
        confirmLoading={state.loading}
        onCancel={() => setModalCommentVisible(false)}
        footer={[
          <Button
            className="custom-cancel-button"
            key="back"
            onClick={() => setModalCommentVisible(false)}
          >
            {t('button.cancel')}
          </Button>,
          <Button
            className="custom-ok-button"
            key="submit"
            type="primary"
            loading={state.loading}
            onClick={() => handleEditComment('save')}
          >
            {t('button.save')}
          </Button>,
          <Button
            key="delete"
            type="primary"
            danger
            style={{
              float: 'left',
              display: formComment.getFieldValue('id')
                ? 'inline-block'
                : 'none',
            }}
            loading={state.loading}
            onClick={() => handleEditComment('delete')}
          >
            {t('Delete')}
          </Button>,
        ]}
      >
        <Form form={formComment}>
          <Item hidden name="id">
            <Input />
          </Item>
          <Item
            label={t('datahub.author')}
            name="author"
            rules={[{ required: true }]}
          >
            <Input
              placeholder={`${t('form.input')}${t('datahub.author')}`}
              autoComplete="off"
            />
          </Item>
          <Item
            label={t('datahub.time')}
            name="time"
            rules={[{ required: true }]}
            getValueProps={(value) => ({
              value: value && moment(value, commentDateFormat),
            })}
          >
            <DatePicker
              placeholder={`${t('form.input')}${t('datahub.time')}`}
              format={commentDateFormat}
              showTime
              style={{ width: '100%' }}
            />
          </Item>
          <Item
            label={t('datahub.comment')}
            name="comment"
            rules={[{ required: true }]}
          >
            <TextArea
              rows={4}
              placeholder={`${t('form.input')}${t('datahub.comment')}`}
            />
          </Item>
        </Form>
      </Modal>
      <CommentsList
        commentsData={commentsData}
        editable={!isDisabled}
        onClose={() => setCommentsData({})}
        onModalCommentVisible={handleModalCommentVisible}
      ></CommentsList>
    </>
  );
};

export default PublishDetail;
