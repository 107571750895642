import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Table,
  Tag,
  Button,
  Upload,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
} from 'antd';
import {
  SyncOutlined,
  DownloadOutlined,
  UploadOutlined,
  CaretRightOutlined,
  SearchOutlined,
  CalendarOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import CommentsList from './CommentsList';
import styles from './../../scss/modules/list.module.scss';
import itemStyles from './../../scss/modules/item.module.scss';
import { DATAHUB_FIELDS } from './../../common';

const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;

const DatahubTable = (props) => {
  const { t } = useTranslation();
  const {
    type,
    loading,
    actionConfig,
    dataSource,
    queryModel,
    pagination,
    onChange,
    onControl,
    onSyncData,
    onDownload,
    onUpload,
    onSearch,
    onAddRemark,
  } = props;
  const { TextArea } = Input;
  const { Option } = Select;
  const { Item } = Form;
  const [form] = Form.useForm();
  const [formRemark] = Form.useForm();
  const [isPublish, setIsPublish] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [modalControlVisible, setModalControlVisible] = useState(false);
  const [modalSyncVisible, setModalSyncVisible] = useState(false);
  const [modalUploadVisible, setModalUploadVisible] = useState(false);
  const [commentsData, setCommentsData] = useState({});
  const [tableFilter, setTableFilter] = useState({});
  const [modalRemarkVisible, setModalRemarkVisible] = useState('');
  const [actionChange, setActionChange] = useState({});
  const [modalTipsVisible, setModalTipsVisible] = useState(false);

  const handleModalControlVisible = (id) => {
    if (actionChange[id]) return setModalControlVisible(id);
    setModalTipsVisible(true);
  };

  const handleAddRemark = async () => {
    const { remark } = await formRemark.validateFields();
    onAddRemark({ id: modalRemarkVisible, remark });
  };

  const handleSearch = (dataIndex, confirm) => {
    confirm();
    onSearch({ [dataIndex]: tableFilter[dataIndex] });
  };

  const handleTableFilter = (filterType, dataIndex, value) => {
    let res;

    if (filterType === 'date') {
      res = value
        ? [
            moment(value[0]).format(dateFormat),
            moment(value[1]).format(dateFormat),
          ]
        : [];
    } else {
      res = value[0] || '';
    }

    setTableFilter({ ...tableFilter, [dataIndex]: res });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ confirm }) => (
      <div
        style={{ display: 'flex', padding: 8, paddingBottom: 0 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder={`Search ${dataIndex}`}
          value={tableFilter[dataIndex]}
          onChange={(e) =>
            handleTableFilter(
              'input',
              dataIndex,
              e.target.value ? [e.target.value] : []
            )
          }
          onPressEnter={() => handleSearch(dataIndex, confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(dataIndex, confirm)}
          style={{ marginLeft: 8, width: 90 }}
        >
          {t('button.search')}
        </Button>
      </div>
    ),
    filterIcon: () => (
      <SearchOutlined
        style={{ color: queryModel[dataIndex] ? '#1890ff' : undefined }}
      />
    ),
  });

  const datePickerValue = (date) => {
    return date?.length === 2
      ? [moment(date[0], dateFormat), moment(date[1]), dateFormat]
      : [];
  };

  const getColumnDateProps = (dataIndex) => ({
    filterDropdown: ({ confirm }) => (
      <div
        style={{ display: 'flex', padding: 8 }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          onChange={(dates) => handleTableFilter('date', dataIndex, dates)}
          value={datePickerValue(tableFilter[dataIndex])}
          style={{ display: 'flex' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(dataIndex, confirm)}
          style={{ marginLeft: 8, width: 90 }}
        >
          Apply
        </Button>
      </div>
    ),
    filterIcon: () => (
      <CalendarOutlined
        style={{
          color: queryModel[dataIndex]?.length === 2 ? '#1890ff' : undefined,
        }}
      />
    ),
  });

  const columns = DATAHUB_FIELDS.filter((item) =>
    item.visible.includes(type)
  ).map((item) => {
    const { key, title, link, width, fixed, isSearch, type } = item;
    let res = { key, dataIndex: key, title, width, fixed };

    if (isSearch) {
      if (type === 'input') res = { ...res, ...getColumnSearchProps(key) };
      if (type === 'date') res = { ...res, ...getColumnDateProps(key) };
    }

    res.render = (value) => {
      const { text, isChange } = value || {};
      return <span className={isChange ? styles.is_change : ''}>{text}</span>;
    };

    if (key === 'recordID') {
      res.render = (value, record) =>
        isPublish && link ? (
          <Link to={`/datahub/publish/${record.id}`}>{value}</Link>
        ) : (
          value
        );
    }

    if (key === 'comments') {
      res.render = (value, record) => {
        const { text } = value[0] || {};
        const { commentsChange } = record;
        return (
          text && (
            <Tag
              className={`${itemStyles.tag} ${itemStyles.maxHeight}`}
              color={commentsChange ? 'red' : 'default'}
              onClick={() => setCommentsData({ title, value })}
            >
              <CaretRightOutlined /> {text}
            </Tag>
          )
        );
      };
    }

    if (key === 'remarks') {
      res.onHeaderCell = () => ({ style: { backgroundColor: '#e2f6ff' } });
      res.render = (value, record) => {
        const { text } = value[0] || {};
        const { id, status, remarksChange } = record;
        return (
          <>
            {text && (
              <Tag
                className={`${itemStyles.tag} ${itemStyles.maxHeight}`}
                color={remarksChange ? 'red' : 'default'}
                onClick={() => setCommentsData({ title, value })}
              >
                <CaretRightOutlined /> {text}
              </Tag>
            )}
            {status === 2 && (
              <Tag
                onClick={() => {
                  formRemark.resetFields();
                  setModalRemarkVisible(id);
                }}
                className={itemStyles.tag}
                style={{
                  marginTop: 8,
                  background: 'white',
                  borderStyle: 'dashed',
                }}
              >
                <PlusOutlined /> {t('button.addRemark')}
              </Tag>
            )}
          </>
        );
      };
    }

    if (key === 'controls') {
      res.onHeaderCell = () => ({ style: { backgroundColor: '#e2f6ff' } });
      res.render = (value, record) => {
        const { id, status, affirmButtonChange } = record || {};
        return (
          status === 2 && (
            <Button
              type="primary"
              onClick={() => handleModalControlVisible(id)}
              style={
                affirmButtonChange
                  ? { background: 'green', borderColor: 'green' }
                  : {}
              }
            >
              {t('datahub.affirm')}
            </Button>
          )
        );
      };
    }

    if (key === 'action') {
      res.onHeaderCell = () => ({ style: { backgroundColor: '#e2f6ff' } });
      res.render = (value, record) => {
        const { text } = value || {};
        const { id, status } = record;
        return status === 2 ? (
          <Select
            placeholder={t('form.select')}
            value={actionChange[id]}
            style={{ width: 120 }}
            onChange={(value) => {
              if (selectedRowKeys.includes(id) && !selectedKeys.includes(id)) {
                setSelectedKeys([...selectedKeys, id]);
              }
              setActionChange({ ...actionChange, [id]: value });
            }}
          >
            {actionConfig.map((item) => (
              <Option key={item.id} value={item.id}>
                {t(`datahub.${item.name}`)}
              </Option>
            ))}
          </Select>
        ) : (
          t(`datahub.${text}`)
        );
      };
    }

    if (key === 'status') {
      res.render = (value) => {
        let str = '';
        switch (value) {
          case 1:
            str = t('datahub.unpublished');
            break;
          case 2:
            str = isPublish ? t('datahub.published') : t('datahub.unaffirmed');
            break;
          case 3:
            str = isPublish ? t('datahub.published') : t('datahub.affirmed');
            break;
          default:
            break;
        }
        return str;
      };
    }

    return res;
  });

  const setFileList = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handModalUploadleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onUpload(values.fileList?.[0]?.originFileObj);
      })
      .catch((errorInfo) => {
        console.log('errorInfo:', errorInfo);
      });
  };

  const handleModalUploadCancel = () => {
    form.resetFields();
    setModalUploadVisible(false);
  };

  const handleSelectChange = (keys, selectedRows) => {
    const _selectKeys = [];

    selectedRows.forEach((item) => {
      const { id, status } = item;

      if (isPublish ? status === 1 : status === 2 && actionChange[id]) {
        _selectKeys.push(id);
      }
    });
    setSelectedKeys(_selectKeys);
    setSelectedRowKeys(keys);
  };

  useEffect(() => {
    if (!loading) {
      setSelectedKeys([]);
      setSelectedRowKeys([]);
      setModalSyncVisible(false);
      setModalControlVisible(false);
      setModalUploadVisible(false);
      setModalRemarkVisible('');
    }
  }, [loading]);

  useEffect(() => {
    let obj = {};

    dataSource.forEach((item) => {
      obj[item.id] = item.action.text;
    });
    setActionChange(obj);
  }, [dataSource]);

  useEffect(() => {
    setTableFilter({ ...queryModel });
  }, [queryModel]);

  useEffect(() => {
    setIsPublish(type === 'publish');
  }, []);

  return (
    <>
      {isPublish && (
        <Button
          style={{ marginLeft: 20 }}
          type="primary"
          icon={<SyncOutlined />}
          onClick={() => setModalSyncVisible(true)}
        >
          {t('button.syncData')}
        </Button>
      )}
      <Button
        style={{ marginLeft: 20 }}
        type="primary"
        icon={<DownloadOutlined />}
        onClick={() => onDownload(selectedRowKeys)}
      >
        {t('button.download')}
      </Button>
      <Button
        style={{ marginLeft: 20 }}
        type="primary"
        icon={<UploadOutlined />}
        onClick={() => {
          form.resetFields();
          setModalUploadVisible(true);
        }}
      >
        {t('button.upload')}
      </Button>
      <div className={styles.wrapper}>
        <Table
          scrollToFirstRowOnChange
          className={styles.table}
          scroll={{ x: 1000, y: 680 }}
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          rowSelection={{
            selectedRowKeys,
            onChange: handleSelectChange,
          }}
          pagination={pagination}
          onChange={onChange}
          rowKey={(record) => record.id}
        />
      </div>
      {selectedKeys.length > 0 && (
        <>
          <div className={styles.footer}>
            <div>
              <Button
                type="primary"
                onClick={() => setModalControlVisible(true)}
              >
                {`${isPublish ? t('datahub.publish') : t('datahub.affirm')} (${
                  selectedKeys.length
                })`}
              </Button>
            </div>
          </div>
        </>
      )}
      <CommentsList
        commentsData={commentsData}
        onClose={() => setCommentsData({})}
      ></CommentsList>
      <Modal
        title={t('common.tips')}
        visible={modalTipsVisible}
        onCancel={() => setModalTipsVisible(false)}
        footer={[
          <Button
            className="custom-cancel-button"
            key="back"
            type="primary"
            onClick={() => setModalTipsVisible(false)}
          >
            {t('button.ok')}
          </Button>,
        ]}
      >
        {t('message.pleaseConfirmActionData')}
      </Modal>
      <Modal
        title={t('common.tips')}
        visible={modalSyncVisible}
        confirmLoading={loading}
        onCancel={() => setModalSyncVisible(false)}
        onOk={onSyncData}
      >
        {t('message.pleaseConfirmSyncData')}
      </Modal>
      <Modal
        title={t('common.tips')}
        visible={modalControlVisible}
        confirmLoading={loading}
        okText={t(`datahub.${type}`)}
        cancelText={t('button.cancel')}
        onCancel={() => setModalControlVisible(false)}
        onOk={() => {
          const values =
            typeof modalControlVisible === 'boolean'
              ? selectedKeys
              : [modalControlVisible];
          onControl(values, actionChange);
        }}
      >
        {t('message.pleaseConfirmOperation', {
          type: t(`datahub.${type}`),
          count: selectedKeys.length || 1,
        })}
      </Modal>
      <Modal
        title={t(`button.upload`)}
        visible={modalUploadVisible}
        confirmLoading={loading}
        onOk={handModalUploadleOk}
        onCancel={handleModalUploadCancel}
        okText={t('button.save')}
        cancelText={t('button.cancel')}
      >
        <Form form={form} preserve={false}>
          <Item
            label={t('importForm.import')}
            name="fileList"
            valuePropName="fileList"
            getValueFromEvent={setFileList}
            rules={[
              {
                required: true,
                message: t('form.required'),
              },
            ]}
          >
            <Upload
              accept=".xls, .xlsx"
              beforeUpload={() => false}
              customRequest={() => {}}
              maxCount={1}
            >
              <Button type="ghost" icon={<UploadOutlined />}>
                {t(`button.upload`)}
              </Button>
            </Upload>
          </Item>
        </Form>
      </Modal>
      <Modal
        title={t(`datahub.remark`)}
        visible={modalRemarkVisible}
        confirmLoading={loading}
        cancelText={t('button.cancel')}
        okText={t('button.save')}
        onCancel={() => setModalRemarkVisible('')}
        onOk={() => handleAddRemark()}
      >
        <Form form={formRemark}>
          <Item
            label={t('datahub.remark')}
            name="remark"
            rules={[{ required: true }]}
          >
            <TextArea
              rows={4}
              placeholder={`${t('form.input')}${t('datahub.remark')}`}
            />
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default DatahubTable;
