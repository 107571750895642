import { Timeline, Drawer, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const CommentsList = (props) => {
  const {
    commentsData: { title, value = [] },
    editable,
    onClose,
    onModalCommentVisible,
  } = props;

  return (
    <>
      <Drawer
        title={title}
        placement="right"
        width={378}
        visible={value.length > 0}
        closable={true}
        onClose={onClose}
      >
        <Timeline>
          {value.map((item, index) => {
            const { author, text, time } = item;
            return (
              <Timeline.Item key={index}>
                - {author}, {time}
                <br />
                {text}
                {editable && (
                  <div>
                    <Button
                      size="small"
                      onClick={() => onModalCommentVisible(item)}
                    >
                      <EditOutlined />
                    </Button>
                  </div>
                )}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Drawer>
    </>
  );
};

export default CommentsList;
